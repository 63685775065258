<template>
    <div class="system-container">
        <el-row>
            <el-button type="primary" size="small" @click="saveCompetitor">确定</el-button>
        </el-row>
        <el-row>
            选择3个竞品(只可修改一次)
        </el-row>
        <el-row>
            <el-col v-for="item in model" :key="item.id" :span="3">
                <div class="comp-btn" @click="checkCompetitor(item)">
                    <div class="comp-img">
                        <img :src="$FormatURL(item.logo)" alt="" οnerrοr="this.src='../../assets/images/marker/com.png'">
                    </div>
                    <div class="comp-name">
                        <span>{{item.type_Name}}</span>
                    </div>
                    
                    <div class="comp-checked" v-if="isCheckedOrNot(item)">
                        <i class="el-icon-check"></i>
                    </div>
                </div>
            </el-col>
        </el-row>
        
    </div>
</template>

<script>
import api from "@/api/index.js";
export default {
    name: "CompititerMaintain",
    data(){
        return {
            model: "",
            cusNo: "",
            checkedList: []
        }
    },
    mounted(){
        this.cusNo = sessionStorage.cusNo;
        this.initPage();
    },
    methods: {
        initPage() {
            api.competitorList({
                cusNo: this.cusNo
            }).then(res => {
                if(res.code == 200 && res.data.length > 0) {
                    this.model = res.data;
                    this.model.map(item => {
                        if(item.selected == "1") {
                            this.checkedList.push(item);
                        }
                    });
                }
            })
        },
        saveCompetitor() {
            let competitor = this.checkedList.map(item => {
                return item.dict_Code;
            }).join(",");
            console.log(competitor);
            api.saveCompetitor({
                cusNo: this.cusNo,
                competitor
            }).then(res => {
                if(res.code == 200) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning"
                    })
                }
            }).catch(res => {
                this.$message.error("竞品编辑接口错误");
            })
        },
        // 选中或者取消选中
        checkCompetitor(item) {
            if(this.checkedList.length == 0) {
                this.checkedList.push(item);
            }else{
                for(var index = 0; index < this.checkedList.length;){
                    var m = this.checkedList[index];
                    if(m.dict_Code == item.dict_Code ){
                        this.checkedList.splice(index,1);
                        break;
                    } else if(index == this.checkedList.length - 1){
                        if(this.checkedList.length == 3) {
                            this.$message({
                                message: "最多选择3个竞品",
                                type: "warning"
                            });
                        } else {
                            this.checkedList.push(item);
                        }
                        break;
                    }
                    index++;
                }
            }
        },
        
        isCheckedOrNot(item) {
            let result = false;
            this.checkedList.map(m => {
                if(m.dict_Code == item.dict_Code)
                    result = true;
            });
            return result;
        }
    }
}
</script>
<style>
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.comp-btn {
    background: #eee;
    border-radius: 5px;
    margin: 10px;
    padding: 2px;
    position: relative;
    overflow: hidden;
    padding-left: 20px;
}
.comp-img {
    width: 27px;
    height: 32px;
    display: inline-block;
    margin-bottom: 3px;
}
.comp-name {
    display: inline-block;
    position: absolute;
    top: 11px;
    margin-left: 2px;
}
.comp-btn img {
    width: 27px;
    height: 32px;
}
.comp-checked {
    display: inline-block;
    width: 0px;
    height: 0px;
    border-color: transparent #0188e2;
    border-width: 20px 20px 0px 0px;
    border-style: solid;
    position: absolute;
    right: 0;
    bottom: 0px;
}
.comp-checked i {
    position: absolute;
    top: -12px;
    right: -19px;
    color: white;
}
</style>
